import { create } from "zustand";

const useFormState = create((set) => ({
  formStep: 0,
  houseId: null,
  formValues: {
    houseId: null,
    first_name_user: null,
    last_name_user: null,
    mail_user: null,
    address: null,
    city: null,
    mobile_phone: null,
    payment_method: "credit_card",
    guest_quantity: 2,
    dog_admitted: false,
    is_dog: false,
  },
  setHouseId: (id) => set({ houseId: id }),
  changeStep: (n) => set({ formStep: n }),
  resetForm: () =>
    set((state) => ({
      ...state,
      formValues: {
        first_name_user: null,
        last_name_user: null,
        mail_user: null,
        address: null,
        city: null,
        mobile_phone: null,
        payment_method: "credit_card",
        guest_quantity: 2,
        is_dog: false,
        dog_admitted: false,
      },
    })),
  setFormValues: (e, key) =>
    set((state) => ({
      formValues: {
        ...state.formValues,
        [key]: e,
      },
    })),
}));

export default useFormState;

/*
export function useFormStatus() {
  const [step, setFormStep] = useState(1);
  const [userFormValues, setUserFormValues] = useState(null);

  return { step, setFormStep, userFormValues, setUserFormValues };
}*/
