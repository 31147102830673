import { useState } from "react";
import Input from "./components/Inputs";
import useFormState from "./hooks/useForm";
import axios from "axios";
import PayPalCheckout from "./components/PayPalCheckout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./components/CheckoutForm";
import { IoIosCalendar, IoMdArrowDropright } from "react-icons/io";
import { IoArrow } from "react-icons/io";
import { FaInfoCircle } from "react-icons/fa";
// PROD KEY
const stripePromise = loadStripe(
  "pk_live_51HossOGSgHnoyqUN0x9a2ihoXiGKHi3BLs8WNeHB1q90KDS2EMnkEPpJHoEJ5aC8SkKmZbHjf67d2gNS6isoAgZG00XdLfwUZD"
);
// TEST KEY
// const stripePromise = loadStripe(
//   "pk_test_51HossOGSgHnoyqUNI5ZwlOvpWbKcayHXqaf9f3rq0NcWx8podVBxNg7H5Lhlpn4QQynCFfyHziYYhfuxIGTCC8Gi00DJhT4chR"
// );

export default function BookingForm(props) {
  const {
    formStep,
    formValues,
    changeStep,
    setFormValues,
    resetForm,
    houseId,
    setHouseId,
  } = useFormState();
  const [showPaypal, setShowPaypal] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const bodyParams = {
      house: houseId,
      arrival: formValues.arrival,
      departure: formValues.departure,
      mail_user: formValues.mail_user,
      name_user: formValues.first_name_user,
      last_name_user: formValues.last_name_user,
      mobile_phone: parseInt(formValues.mobile_phone),
      payment_method: formValues.payment_method,
      guest_quantity: parseInt(formValues.guest_quantity),
      is_dog: formValues.is_dog.toString(),
    };
    const response = await axios.post(
      "https://app.friland.it/v1/booking/new",
      bodyParams
    );
    // set client id returned from payment intent ( stripe )
    if (formValues.payment_method === "credit_card") {
      paymentIntendStarted(response);
      const amount = response.data.amount / 100;
      setFormValues(amount, "price");
    }
    // set price for paypal
    if (formValues.payment_method === "paypal") {
      setFormValues(response.data.newBook.price, "price");
      setFormValues(response.data.newBook.id, "bookingId");
    }
    changeStep(2);
    setTimeout(() => {
      alert(
        "Hai impiegato troppo tempo ad inserire i dati. Verrai riportato al calendario di prenotazione."
      );
      backAndReset();
    }, 300000);
  };

  const backAndReset = () => {
    // If url id is "0" means we started from generic selection, so we set house id to 0 to show previous general calendar.
    const houseId = window.location.pathname.split("/");
    if (houseId[1] && houseId[1] === "0") {
      setHouseId("0");
    }
    changeStep(0);
    resetForm();
  };
  const [clientSecret, setClientSecret] = useState();
  const paymentIntendStarted = (value) => {
    const options = {
      clientSecret: value.data.client_secret,
      appearance: {
        variables: {
          colorPrimary: "#254351",
          colorText: "#254351",
        },
      },
    };
    setClientSecret(options);
    changeStep(2);
  };
  // If dog is selected we need to re-calculate price
  const handleDogChange = async (value) => {
    setFormValues(value, "is_dog");
    const response = await axios
      .get(
        `https://app.friland.it/v1/booking/price/${houseId}/${formValues.arrival}/${formValues.departure}/${value}`
      )
      .then((res) => {
        setFormValues(res.data.totalPriceDiscounted, "price");
        setFormValues(res.data.dog, "dog_price");
      });
    await response;
  };

  return (
    <div className="w-100 flex-row justify-center">
      <div className="justify-center w-100 flex bg-transparent p-4 rounded-lg">
        {formStep === 1 && (
          <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div className="flex gap-2 w-full">
              <Input
                required
                label="Nome"
                onChange={(e) =>
                  setFormValues(e.target.value, "first_name_user")
                }
                value={formValues.first_name_user}
              />
              <Input
                label="Cognome"
                onChange={(e) =>
                  setFormValues(e.target.value, "last_name_user")
                }
                value={formValues.last_name_user}
              />
            </div>

            <Input
              label="Email"
              type="email"
              placeholder="mario@rossi@gmail.com"
              onChange={(e) => setFormValues(e.target.value, "mail_user")}
              value={formValues.mail_user}
            />

            <Input
              label="Telefono"
              type="number"
              maxLength={14}
              onChange={(e) => setFormValues(e.target.value, "mobile_phone")}
              value={formValues.mobile_phone}
            />

            <div className="flex flex-col">
              <label className="text-blue"> Numero di persone </label>
              <select
                defaultValue="2"
                onChange={(e) =>
                  setFormValues(e.target.value, "guest_quantity")
                }
                className="p-2 text-md border-2 text-blue w-full rounded-md outline-none focus:border-2 transition focus:border-blue disabled:opacity-70 disabled:cursor-not-allowed"
              >
                <option value="1"> 1 adulto </option>
                <option value="2" default>
                  {" "}
                  2 adulti{" "}
                </option>
                <option value="3"> 2 adulti + 1 bambino </option>
              </select>
            </div>
            <div className="flex flex-col">
              <label className="text-blue"> Metodo di pagamento </label>
              <select
                defaultValue="Carta di credito"
                required
                onChange={(e) =>
                  setFormValues(e.target.value, "payment_method")
                }
                className="p-2 text-md border-2 text-blue w-full rounded-md outline-none focus:border-2 transition focus:border-blue disabled:opacity-70 disabled:cursor-not-allowed"
              >
                <option value="credit_card"> Carta di credito </option>
                <option value="paypal"> Paypal </option>
              </select>
            </div>
            {formValues.dog_admitted ? (
  <div className="flex flex-col">
    <div className="flex">
      <label className="text-blue" htmlFor="dog">
        Porto il cane?
      </label>
    </div>
    <select
      id="dog"
      className="p-2 text-md border-2 text-blue w-full rounded-md outline-none focus:border-2 transition focus:border-blue disabled:opacity-70 disabled:cursor-not-allowed"
      required
      onChange={(e) => handleDogChange(e.target.value)}
    >
      <option value="" disabled selected hidden>
        Scegli
      </option>
      <option value="true">Sì</option>
      <option value="false">No</option>
    </select>
    {formValues.is_dog === "true" && (
      <div className="ml-2 text-blue flex items-center">
        <FaInfoCircle />
        <span className="flex ml-2">
          Supplemento pulizia: {formValues.dog_price} €
        </span>
      </div>
    )}
  </div>
) : (
  <div className="flex flex-col">
    <div className="flex">
      <label className="text-blue" htmlFor="dog">
        Porto il cane?
      </label>
    </div>
    <select
      id="dog"
      className="p-2 text-md border-2 text-blue w-full rounded-md outline-none focus:border-2 transition focus:border-blue disabled:opacity-70 disabled:cursor-not-allowed"
      required
    >
      <option value="false" disabled selected hidden>
        Cane non ammesso
      </option>
    </select>
  </div>
)}

                
          

            <div className="flex text-sm items-center w-full justify-between">
              <div className="flex items-center">
                <input type="checkbox" id="consent" className="flex" required />
                <div for="consent" className="ml-2 flex">
                  <label for="consent">
                    Accetto i{" "}
                    <a
                      className="text-blue hover:underline"
                      target="_blank"
                      href="https://www.iubenda.com/termini-e-condizioni/36998847"
                    >
                      Termini e Condizioni{" "}
                    </a>
                    e{" "}
                    <a
                      className="text-blue hover:underline"
                      target="_blank"
                      href="https://www.iubenda.com/privacy-policy/36998847"
                    >
                      Norme sulla Privacy{" "}
                    </a>
                  </label>
                </div>
              </div>
              <div
                className="text-sm underline cursor-pointer text-blue items-center"
                onClick={backAndReset}
              >
                Annulla
              </div>
            </div>

            <button
              type="submit"
              className="w-full text-white bg-blue my-4 rounded-lg border-blue border-solid border-2  py-3 disabled:cursor-not-allowed transition-colors disabled:bg-slate-400 disabled:text-gray-600 hover:bg-yellow hover:text-blue hover:border-blue"
            >
              Conferma
            </button>
          </form>
        )}
        {formStep === 2 && (
          <div className="flex flex-col">
            <div className="my-4">
              <h2 className="text-lg font-bold mb-3"> Riepilogo </h2>
              <div className="flex items-center text-base text-blue">
                {" "}
                <IoIosCalendar />
                <p className="!text-[#666666] text-base font-normal">
                  {formValues.arrival} - {formValues.departure}
                </p>
              </div>

              <h3 className="font-bold text-lg my-3">
                {" "}
                Prezzo totale : {formValues.price} €{" "}
              </h3>
            </div>
            <div>
              {" "}
              {formValues.payment_method === "credit_card" && (
                <Elements
                  stripe={stripePromise}
                  key={clientSecret}
                  options={clientSecret}
                >
                  <CheckoutForm />
                </Elements>
              )}
              {formValues.payment_method === "paypal" && (
                <PayPalCheckout></PayPalCheckout>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
