const Input = ({
  placeholder,
  value,
  type,
  disabled,
  onChange,
  maxLength,
  label,
}) => {
  return (
    <div className="flex flex-col flex-grow">
      <label className="text-blue"> {label}</label>

      <input
        disabled={disabled}
        onChange={onChange}
        value={value}
        type={type}
        required
        placeholder={placeholder}
        maxLength={maxLength}
        className="
        p-2
        text-md
        border-2
        text-blue
        w-full
        rounded-md
        outline-none
        focus:border-2
        transition
        focus:border-blue
        disabled:opacity-70
        disabled:cursor-not-allowed"
      ></input>
    </div>
  );
};

export default Input;
