import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import useFormState from "../hooks/useForm";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://fri.land/thank-you-page/",
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <div className="max-w-[700px]">
      <form onSubmit={handleSubmit}>
        <PaymentElement />

        <button
          disabled={!stripe}
          type="submit"
          className="w-full text-white bg-blue mt-4 rounded-lg border-blue border-solid border-2 py-3 disabled:cursor-not-allowed transition-colors disabled:bg-slate-400 disabled:text-gray-600 hover:bg-yellow hover:text-blue hover:border-blue"
        >
          Conferma
        </button>
      </form>
    </div>
  );
};

export default CheckoutForm;
