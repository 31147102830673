import { CLIENT_ID } from "../Config/Config";
import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import useFormState from "../hooks/useForm";
import axios from "axios";

export default function PayPalCheckout() {
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const { formStep, formValues, changeStep, setFormValues, resetForm } =
    useFormState();

  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Friland",
            amount: {
              currency_code: "EUR",
              value: formValues.price,
            },
          },
        ],
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      confirmSuccess(true);
      changeStep(0);
      resetForm();
    });
  };
  /** Sends confirmation to BE */
  const confirmSuccess = async (value) => {
    const response = axios
      .post(
        `https://app.friland.it/v1/booking/payed/${formValues.bookingId}/${value}`
      )
      .then(() => {
        window.location.replace("https://fri.land/thank-you-page/");
      });
    return response;
  };

  //capture likely error
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
    confirmSuccess(false);
    resetForm();
    changeStep(0);
  };

  return (
    <PayPalScriptProvider options={{ "client-id": CLIENT_ID, currency: "EUR" }}>
      <div>
        <PayPalButtons
          fundingSource="paypal"
          style={{ layout: "vertical" }}
          createOrder={createOrder}
          onApprove={onApprove}
          onError={onError}
        />
      </div>
    </PayPalScriptProvider>
  );
}
