import React from "react";

export default function StartEndDates({ openCalendar, savedValue, separator }) {
  let from = savedValue ? savedValue[0].format() : "";
  let to = savedValue ? savedValue[1].format() : "";

  return (
    <>
      <div
        className="flex w-full border border-blue rounded-lg"
        onClick={openCalendar}
      >
        <div className="w-1/2 border-r border-blue py-1 px-2">
          <p className="text-[10px]  text-blue">CHECK IN</p>
          <p className="min-h-[1.5rem] text-sm"> {from}</p>
        </div>
        <div className="w-1/2 py-1 px-2">
          <p className="text-[10px]  text-blue">CHECK OUT</p>
          <p className="min-h-[1.5rem] text-sm"> {to}</p>
        </div>
      </div>
    </>
  );
}
