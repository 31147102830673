import DatePicker from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";
import { useState, useRef, useEffect } from "react";
import StartEndDates from "../StartEndDates";
import gregorian_it from "../Utils/gregorian_it";
import useFormState from "../hooks/useForm";
import axios from "axios";
import LoadingSpinner from "./Spinner/LoadingSpinner";

export default function GenericCalendar() {
  const datesOnlyCheckOut = [''];
  const datesMinTwoNights = ['2024-08-15'];
  const datesOnlyCheckIn = [''];
  const { changeStep, setFormValues, formValues, setHouseId, houseId } =
    useFormState();
  const [value, setValue] = useState([]);
  const [formattedDates, setFormattedDates] = useState([]);
  const [savedValue, setSavedValue] = useState();
  const datePickerRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [availableBooks, setAvailableBooks] = useState(null);
  const [availableDates, setAvailableDates] = useState([]);
  const [availableBooksWithPrice, setAvailableBooksWithPrice] = useState([]);
  const [calendarBounds, setCalendarBounds] = useState();
  const [data, setData] = useState({});

  const handleBook = (id) => {
    setFormValues(
      new DateObject(savedValue[0])?.format("MM-DD-YYYY"),
      "arrival"
    );
    setFormValues(
      new DateObject(savedValue[1])?.format("MM-DD-YYYY"),
      "departure"
    );

    setHouseId(id);
    // Check if dog admitted, set value if true
    setFormValues(
      availableBooks.find((book) => book.house.id === id).house.dog_admitted,
      "dog_admitted"
    );
    changeStep(1);
  };

  // Returns a list of dates with relative availability
  const fetchAvailableDays = async () => {
    const today = new DateObject();
    const todayString = today.format("MM-DD-YYYY"); // giorno-mese-anno
    const lastDate = today.add(6, "month");
    const lastDateString = lastDate.format("MM-DD-YYYY"); // giorno-mese-anno
    const response = axios
      .get(
        `https://friland-app-backend.visup.me/v1/vacancy/allHouses/${todayString}/${lastDateString}`
      )
      .then((res) => {
        // Mark checkout only dates
        const markedResultData = res.data.map((date, index) => {
          if ((!date.vacancy && res.data[index - 1].vacancy && index > 0 )|| (datesOnlyCheckOut.includes(String(date.day)))) {
            date.checkout_only = true;
          } else if (datesMinTwoNights.includes(String(date.day))){
            date.minTwoNights = true;
          } else if (datesOnlyCheckIn.includes(String(date.day))) {
            date.checkIn_only = true; 
          }
          return date;
        });
        setAvailableDates(markedResultData);
      });
    const data = await response.data;
    return data;
  };
  const fetchCalendarOpeningDays = async () => {
    const response = axios
      .get(`https://friland-app-backend.visup.me/v1/vacancy/allHousesSimple`)
      .then((res) => {
        setCalendarBounds(res.data);
      });
    const data = await response.data;
    return data;
  };
  const fetchDates = async (savedValues) => {
    setIsLoading(true);
    const response = axios
      .get(
        `https://app.friland.it/v1/days/vacancyAll//${savedValues[0]}/${savedValues[1]}`
      )
      .then((res) => {
        setAvailableBooks(res.data.filter((book) => book.show));
      });

    const data = await response.data;
    return data;
  };

  useEffect(() => {
    setAvailableBooksWithPrice([]);
    // Make individual API calls when availableBooks changes
    if (availableBooks?.length > 0) {
      // Check price for each house available
      availableBooks?.forEach((item) => {
        axios
          .get(
            `https://app.friland.it/v1/booking/price/${item.house.id}/${formattedDates[0]}/${formattedDates[1]}/false`
          )
          .then((res) => {
            // Override calculated total price from house id detail.
            let bookToUpdate = availableBooks.find(
              (book) => book.house.id === item.house.id
            );
            bookToUpdate.totalPrice = res.data.totalPriceDiscounted;
            setAvailableBooksWithPrice((value) => {
              return [bookToUpdate, ...value];
            });
          });
      });
    }
    setIsLoading(false);
  }, [availableBooks]);

  // Handle calendar save / search
  const handleSave = () => {
    if (!value || !value[0] || !value[1]) {
      datePickerRef.current.closeCalendar();
      return;
    }
    //Format values

    const formattedValues = [
      value[0].format("MM-DD-YYYY"),
      value[1].format("MM-DD-YYYY"),
    ];
    setFormattedDates(formattedValues);
    fetchDates(formattedValues);
    datePickerRef.current.closeCalendar(); // se premo salva chiudo il calendario
  };
  const handleClose = () => {
    setValue(savedValue);
  };

  const handleChange = (range) => {
    // L'utente non può selezionare la stessa data per arrivo e partenza.
    if (range[0]?.format() === range[1]?.format()) {
      const prova = range[1]?.format("YYYY-MM-DD")
      console.log('sono dentro', prova);
      range = range.slice(0, 0);
      setValue(range);
      return;
    }
    if (datesOnlyCheckOut.includes(range[0]?.format("YYYY-MM-DD")) ){
      range = range.slice(0, 0);
      setValue(range);
      return;
    }
    if (datesMinTwoNights.includes(range[0]?.format("YYYY-MM-DD")) || datesMinTwoNights.includes(range[1]?.format("YYYY-MM-DD")) ){
      range = range.slice(0, 0);
      setValue(range);
      return;
    }
    if (datesOnlyCheckIn.includes(range[1]?.format("YYYY-MM-DD")) ){
      range = range.slice(0, 0);
      setValue(range);
      return;
    }

    // if (range[0]?.format())
    // se range[0] è compreso tra l'elenco di luca range[1] deve essere almeno range[0] più due.

    const selectedUnavailableDates = availableDates.some((d) => {
    
      if (d.vacancy === false) {
        // se la data non è disponibile controllo che non sia nel range delle date selezionate
        const [year, month, day] = d.day.split("-");
        const date = new DateObject();
        date.set({ year: year, month: month, day: day });
        const dateBefore = new DateObject(range[1]).subtract(1, "d");
        // This is the case where range 1 could be an only checkout date
        // we check if range[1] exists, if its not available and we check if the date before range[1] is available
        if (range[1]?.format() === date.format() && d.checkout_only) {
          // now we need to check that we dont have unavailable dates between range[0] and datebefore
          if (date.unix > range[0]?.unix && date.unix < dateBefore?.unix)
            //if we have unavailable dates we return true ( invalid range )
            return true;
          else {
            // we selected a range avaiable with range[1] as checkout only date
            return false;
          }
        }

        const selectedUnavailableDate =
          date.format() === range[0]?.format() ||
          date.format() === range[1]?.format();
           // ho selezionato esattamente una data non disponibile
        const selectedUnavailableDateRange =
          date.unix > range[0]?.unix && date.unix < range[1]?.unix; // ho selezionato un range di date che include una data non disponibile
        return selectedUnavailableDate || selectedUnavailableDateRange;
      }
      return false;
    });
    if (selectedUnavailableDates) {
      return false;
    }

    if (range[0] && range[1]) {
      setValue(range);
      setSavedValue(range);
    }
  };
  const checkAvailability = (dateToCheck) => {
    const selectedUnavailableDates = availableDates.some((d) => {
      const [year, month, day] = d.day.split("-");
      const date = new DateObject();
      date.set({ year: year, month: month, day: day });

      if (dateToCheck.format() === date.format() && !d.vacancy) {
        return true;
      }
    });
    if (selectedUnavailableDates) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    fetchAvailableDays();
    fetchCalendarOpeningDays();
  }, []);

  const daysMapping = ({ date }) => {
    let className;
    //Disabilita le date precedenti ad oggi e quelle superiori alla calendar close date
    if (
      date.unix <= new DateObject().toUnix() ||
      date.unix < new DateObject(calendarBounds.open).toUnix() ||
      date.unix > new DateObject(calendarBounds.close).toUnix()      
    ) {
      let props = {};
      props.disabled = true;
      return {
        className: "text-gray-300 line-through pointer-events-none",
        disabled: true,
      };
    }
    if (
      date.unix - new DateObject().unix < -10 ||
      availableDates.find((d) => {
        return d.day === date.format("YYYY-MM-DD").toString();
      })?.vacancy === false
    ) {
      // Se la data non è disponibile ma il giorno prima lo è, la contrassegno come checkout only.
      if (checkAvailability(new DateObject(date.format()).subtract(1, "d"))){
        className = "text-gray-400";
      } else {
        className = "text-gray-300 line-through pointer-events-none";
      }
      // con zero al posto di -10 non funziona. Controlla che la data non sia precedente a oggi o una di quelle non disponibili
    }
    else {
      if (datesOnlyCheckOut.includes(date.format("YYYY-MM-DD").toString()) || datesOnlyCheckIn.includes(date.format("YYYY-MM-DD").toString()) || datesMinTwoNights.includes(date.format("YYYY-MM-DD").toString())) {
        className = "text-gray-400";
      }
    }
    return {
      className,
      onClick: (e) => {
        let spanRect = e.target.getBoundingClientRect();
        let calendarRect = datePickerRef.current
          .querySelector(".rmdp-wrapper")
          .getBoundingClientRect();
        const dateFound = availableDates.find((el) => {
          if (el.day.replaceAll("-", "/") === date.format()) {
            return true;
          }
        });
        if (dateFound.checkout_only ) {
          setData({
            ...data,
            left: spanRect.left - calendarRect.left,
            top: spanRect.top - calendarRect.top,
            visibleCheckOut: true,
          });
        }
        if (dateFound.minTwoNights) {
          setData({
            ...data,
            left: spanRect.left - calendarRect.left,
            top: spanRect.top - calendarRect.top,
            visibleMinTwoNights: true,
          });
        }
        if (dateFound.checkIn_only) {
          setData({
            ...data,
            left: spanRect.left - calendarRect.left,
            top: spanRect.top - calendarRect.top,
            visibleCheckIn: true,
        });
      }},
      onMouseLeave: () => {
        setTimeout(() => (
         setData({
          ...data,
          visibleCheckOut:false,
          visibleMinTwoNights: false,
          visibleCheckIn:false
        })), 3000)
      },
    };
  };

  return (
    <div className="w-full bg-[#F6E3BE] h-[100vh] mobile-generic">
      <div className="flex flex-col bg-yellow rounded-[25px] shadow-lg py-4 px-4">
        <div className=" flex justify-between items-center md:flex-row w-full flex-col gap-8  h-100  generic-calendar">
          <DatePicker
            className="bg-[#F6E3BE] !shadow-none !rounded-lg"
            ref={datePickerRef}
            fixRelativePosition={false}
            // traduzione italiano
            locale={gregorian_it}
            // posizione calendario
            // mostro i giorni precedenti e successivi
            showOtherDays={false}
            // rimuovo l'highlight del giorno corrente
            highlightToday={false}
            // inizio la settimana con lunedì
            weekStartDayIndex={1}
            //mostro due mesi
            numberOfMonths={2}
            // abilito la selezione di un range di date
            range
            value={value}
            mapDays={daysMapping}
            onChange={handleChange}
            onClose={handleClose}
            // renderizzo il componente custom
            render={<StartEndDates savedValue={savedValue} />}
            // mapping dei giorni disponibili e non disponibili
          >
            <div className="flex justify-start p-3 bg-light-yellow">
              <button
                className="text-white text-lg bg-blue rounded-lg py-3 px-10"
                onClick={handleSave}
              >
                Cerca
              </button>
            </div>
            <span
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                backgroundColor: "white",
                border: "1px solid #ccc",
                boxShadow: "0 0 5px #ccc",
                borderRadius: "5px",
                padding: "3px 5px",
                fontSize: "14px",
                transform: `translate(${data.left}px, ${data.top + 30}px)`,
                visibility: data.visibleCheckOut ? "visible" : "hidden",
              }}
            >
              Solo CheckOut
            </span>
            <span
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                backgroundColor: "white",
                border: "1px solid #ccc",
                boxShadow: "0 0 5px #ccc",
                borderRadius: "5px",
                padding: "3px 5px",
                fontSize: "14px",
                transform: `translate(${data.left}px, ${data.top + 30}px)`,
                visibility: data.visibleMinTwoNights ? "visible" : "hidden",
              }}
            >
              Min due notti
            </span>
            <span
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                backgroundColor: "white",
                border: "1px solid #ccc",
                boxShadow: "0 0 5px #ccc",
                borderRadius: "5px",
                padding: "3px 5px",
                fontSize: "14px",
                transform: `translate(${data.left}px, ${data.top + 30}px)`,
                visibility: data.visibleCheckIn ? "visible" : "hidden",
              }}
            >
              Solo CheckIn
            </span>
          </DatePicker>

          <button
            onClick={handleSave}
            /* disabled={!savedValue} */ className="md:max-w-[200px] w-[100%] flex items-center justify-center h-[47px] text-white mx-2 bg-blue  rounded-lg disabled:cursor-not-allowed disabled:bg-slate-400 disabled:text-gray-600 hover:bg-transparent hover:text-blue transition-colors hover:border-blue border-blue border-solid border-2"
          >
            Cerca
          </button>
        </div>

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          availableBooksWithPrice?.map((book) => (
            <div class="flex  items-center my-3 flex-wrap justify-center md:justify-between">
              <span className="text-md font-bold md:w-100 min-w-[300px] text-center md:text-left">
                {" "}
                {book.house.address}
              </span>

              <div className="flex flex-col md:flex-row items-center text-center md:text-left">
                <span className="text-md mr-5">
                  <span className="font-bold">{book.totalPrice} €</span> /{" "}
                  {book.price?.length} notti
                </span>
                <button
                  className="max-h-[40px] flex items-center m-2"
                  onClick={() => handleBook(book.house.id)}
                >
                  Prenota
                </button>
              </div>
            </div>
          ))
        )}
        {availableBooks?.length === 0 && !isLoading && savedValue && (
          <span className="mt-3">
            Nessun alloggio disponibile per le date che hai selezionato
          </span>
        )}
      </div>
    </div>
  );
}
