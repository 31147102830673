import DatePicker from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";
import { useState, useRef, useEffect } from "react";
import StartEndDates from "./StartEndDates";
import gregorian_it from "./Utils/gregorian_it";
import { useQuery } from "@tanstack/react-query";
import useFormState from "./hooks/useForm";
import { FaInfoCircle } from "react-icons/fa";

import axios from "axios";
export default function Calendar() {
  const datesOnlyCheckOut = ['',];
  const datesMinTwoNights = ['2024-08-15'];
  const datesOnlyCheckIn = [''];
  const { changeStep, setFormValues, formValues } = useFormState();
  const [value, setValue] = useState([]);
  const [savedValue, setSavedValue] = useState();
  const datePickerRef = useRef();
  const [price, setPrice] = useState(null);
  const [showPriceDetails, setShowPriceDetails] = useState(false);
  const [datesAvailability, setDatesAvailability] = useState([]);
  const [selectedDatesPrice, setSelectedDatesPrice] = useState();
  const [data, setData] = useState({});

  /* const queryClient = useQueryClient(); */
  useEffect(() => {
    const houseId = window.location.pathname.split("/");
    if (houseId[1]) {
      setFormValues(houseId[1], "houseId");
      fetchHouseDetail(houseId[1]);

      // Retrieve house detail
    }
  }, []);

  const fetchDates = async () => {
    const houseId = window.location.pathname.split("/");
    const today = new DateObject();
    const todayString = today.format("MM-DD-YYYY"); // giorno-mese-anno
    const lastDate = today.add(2, "year");
    const lastDateString = lastDate.format("MM-DD-YYYY"); // giorno-mese-anno

    const response = axios
      .get(
        `https://app.friland.it/v1/days/vacancy/${houseId[1]}/${todayString}/${lastDateString}`
      )
      .then((res) => {
        setDatesAvailability(res.data);
      });
    const data = await response.data;
    return data;
  };

  const fetchHouseDetail = async (houseId) => {
    const response = axios
      .get(`https://app.friland.it/v1/houses/id/${houseId}`)
      .then((res) => {
        setFormValues(res.data.dog_admitted, "dog_admitted");
        setFormValues(res.data.calendar_open_at, "calendar_open_at");
        setFormValues(res.data.calendar_close_at, "calendar_close_at");
        fetchDates();
      });
    const data = await response.data;
    return data;
  };

  const fetchPrice = async (savedValues) => {
    const response = await axios
      .get(
        `https://app.friland.it/v1/booking/price/${formValues.houseId}/${savedValues[0]}/${savedValues[1]}/${formValues.is_dog}`
      )
      .then((res) => {
        setSelectedDatesPrice(res.data);
        const { totalPrice, days, discount, totalPriceDiscounted } = res.data;
        days.map((d) => (d.dayDate = stringToDateObject(d.dayDate)));
        days.sort((a, b) => {
          if (a.dayDate.toUnix() < b.dayDate.toUnix()) return -1;
          if (a.dayDate.toUnix() > b.dayDate.toUnix()) return 1;
          return 0;
        });
        days.map((d) => (d.dayDate = d.dayDate.format()));
        setPrice({
          totalPrice,
          daysDetails: days,
          nights: days.length,
          discount,
          totalPriceDiscounted,
        });
      });
    await response;
  };

  const handleSave = () => {
    if (!value || !value[0] || !value[1]) {
      datePickerRef.current.closeCalendar();
      return;
    }

    //Format values
    const formattedValues = [
      value[0].format("MM-DD-YYYY"),
      value[1].format("MM-DD-YYYY"),
    ];

    setSavedValue(value);
    fetchPrice(formattedValues);
    datePickerRef.current.closeCalendar(); // se premo salva chiudo il calendario
  };
  const handleClose = () => {
    setValue(savedValue);
  };
  const checkAvailability = (dateToCheck) => {
    const selectedUnavailableDates = datesAvailability.some((d) => {
      const date = stringToDateObject(d.day_date); // data che potrebbe essere prenotata
      if (dateToCheck.format() === date.format() && !d.vacancy_is_available) {
        return true;
      }
    });
    if (selectedUnavailableDates) {
      return false;
    } else {
      return true;
    }
  };
  const handleChange = (range) => {
    // L'utente non può selezionare la stessa data per arrivo e partenza.
    if (range[0]?.format() === range[1]?.format()) {
      range = range.slice(0, 0);
      setValue(range);
      return;
    }
    if (datesOnlyCheckOut.includes(range[0]?.format("YYYY-MM-DD")) ){
      range = range.slice(0, 0);
      setValue(range);
      return;
    }
    if (datesMinTwoNights.includes(range[0]?.format("YYYY-MM-DD")) || datesMinTwoNights.includes(range[1]?.format("YYYY-MM-DD")) ){
      range = range.slice(0, 0);
      setValue(range);
      return;
    }
    if (datesOnlyCheckIn.includes(range[1]?.format("YYYY-MM-DD")) ){
      range = range.slice(0, 0);
      setValue(range);
      return;
    }

    const selectedUnavailableDates = datesAvailability.some((d) => {
      if (d.vacancy_is_available === false) {
        // se la data non è disponibile controllo che non sia nel range delle date selezionate
        const date = stringToDateObject(d.day_date); // data che potrebbe essere prenotata
        const dateBefore = new DateObject(range[1]).subtract(1, "d");
        // This is the case where range 1 could be an only checkout date
        // we check if range[1] exists, if its not available and we check if the date before range[1] is available
        if (
          range[1] &&
          !checkAvailability(range[1]) &&
          checkAvailability(dateBefore)
        ) {
          // now we need to check that we dont have unavailable dates between range[0] and datebefore
          if (date.unix > range[0]?.unix && date.unix < dateBefore?.unix)
            //if we have unavailable dates we return true ( invalid range )
            return true;
          else {
            // we selected a range avaiable with range[1] as checkout only date
            return false;
          }
        }

        const selectedUnavailableDate =
          date.format() === range[0]?.format() ||
          date.format() === range[1]?.format(); // ho selezionato esattamente una data non disponibile
        const selectedUnavailableDateRange =
          date.unix > range[0]?.unix && date.unix < range[1]?.unix; // ho selezionato un range di date che include una data non disponibile
        return selectedUnavailableDate || selectedUnavailableDateRange;
      }
      return false;
    });
    if (selectedUnavailableDates) {
      return false;
    } else {
      if (range[0] && range[1]) {
        const formattedValues = [
          range[0].format("MM-DD-YYYY"),
          range[1].format("MM-DD-YYYY"),
        ];
        setValue(range);
        fetchPrice(formattedValues);
      }
    }
  };

  const daysMapping = ({ date }) => {
    let className;
    //Disabilita le date precedenti ad oggi e quelle superiori alla calendar close date
    if (
      date.unix < new DateObject().toUnix() ||
      date.unix > new DateObject(formValues.calendar_close_at).toUnix() ||
      date.unix < new DateObject(formValues.calendar_open_at).toUnix()
    ) {
      let props = {};
      props.disabled = true;
      return {
        className: "text-gray-300 line-through pointer-events-none",
        disabled: true,
      };
    }
    if (
      date.unix - new DateObject().unix < -10 ||
      datesAvailability.find((d) => {
        return (
          stringToDateObject(d.day_date).format("MM/DD/YYYY") ===
          date.format("MM/DD/YYYY")
        );
      })?.vacancy_is_available === false
    ) {
      // Se la data non è disponibile ma il giorno prima lo è, la contrassegno come checkout only.
      if (checkAvailability(new DateObject(date.format()).subtract(1, "d"))) {
        className = "text-gray-400";
      } else {
        className = "text-gray-300 line-through pointer-events-none";
      }
      // con zero al posto di -10 non funziona. Controlla che la data non sia precedente a oggi o una di quelle non disponibili
    } else {
      if (datesOnlyCheckOut.includes(date.format("YYYY-MM-DD").toString()) || datesOnlyCheckIn.includes(date.format("YYYY-MM-DD").toString()) || datesMinTwoNights.includes(date.format("YYYY-MM-DD").toString())) {
        className = "text-gray-400";
      } else {
        className = "text-gray";
      }
    }
    return {
      className,
      onClick: (e) => {
        let spanRect = e.target.getBoundingClientRect();
        let calendarRect = datePickerRef.current
          .querySelector(".rmdp-wrapper")
          .getBoundingClientRect();
        if (
          (checkAvailability(new DateObject(date.format()).subtract(1, "d")) &&
          !checkAvailability(date)) || (datesOnlyCheckOut.includes(date.format("YYYY-MM-DD").toString()))
        ) {
          setData({
            ...data,
            left: spanRect.left - calendarRect.left,
            top: spanRect.top - calendarRect.top,
            visibleCheckOutOnly: true,
          });
        }
        else if (datesMinTwoNights.includes(date.format("YYYY-MM-DD").toString())){
          setData({
            ...data,
            left: spanRect.left - calendarRect.left,
            top: spanRect.top - calendarRect.top,
            visibleMinTwoNights: true,
          });
        }
        else if (datesOnlyCheckIn.includes(date.format("YYYY-MM-DD").toString())){
          setData({
            ...data,
            left: spanRect.left - calendarRect.left,
            top: spanRect.top - calendarRect.top,
            visibleCheckInOnly: true,
          });
        }
      },
      onMouseLeave: () => {
        setTimeout(() => (
          setData({
           ...data,
           visibleCheckOut:false,
           visibleMinTwoNights: false,
           visibleCheckIn:false
         })), 3000)
      },
    };
  };

  // funzione per convertire una stringa in un DateObject
  const stringToDateObject = (string) => {
    const [month, day, year] = string.split("/");
    const date = new DateObject();
    date.set({ year: year, month: month, day: day });
    return date;
  };

  const handleBooking = () => {
    if (savedValue && savedValue[0] && savedValue[1]) {
      setFormValues(
        new DateObject(savedValue[0])?.format("MM-DD-YYYY"),
        "arrival"
      );
      setFormValues(
        new DateObject(savedValue[1])?.format("MM-DD-YYYY"),
        "departure"
      );
      changeStep(1);
    }
  };

  const closeCalendar = () => {
    datePickerRef.current.closeCalendar();
  };

  return (
    <div className="mobile w-full bg-[#F6E3BE] h-[100vh]">
      <div className="bg-yellow w-full py-4 px-4 rounded-[25px] h-100 shadow-lg border-blue">
        <div className="mb-4">
          <h2 className="text-lg">
            {" "}
            <b>Friland Plus</b><br /> Vivi più notti ad un prezzo speciale{" "}
          </h2>{" "}
        </div>
        {datesAvailability ? (
          <DatePicker
            className="bg-[#F6E3BE] !shadow-none !rounded-lg"
            ref={datePickerRef}
            fixRelativePosition={true}
            // traduzione italiano
            locale={gregorian_it}
            // posizione calendario
            // mostro i giorni precedenti e successivi
            showOtherDays={false}
            // rimuovo l'highlight del giorno corrente
            highlightToday={false}
            // inizio la settimana con lunedì
            weekStartDayIndex={1}
            //mostro due mesi
            numberOfMonths={2}
            // abilito la selezione di un range di date
            range
            value={value}
            onChange={handleChange}
            onClose={handleClose}
            // renderizzo il componente custom
            render={<StartEndDates savedValue={savedValue} />}
            // mapping dei giorni disponibili e non disponibili
            mapDays={daysMapping}
          >
            <div className="bg-[#fffbf0] w-full  flex-wrap py-4 px-3 flex justify-between items-center">
              {price && value ? (
                <>
                  <div className="absolute -top-[4.2rem] left-0 w-full bg-[#fffbf0]  pt-3 text-2xl font-bold text-left px-3">
                    {price.nights} Notte / i - {price.totalPrice} €
                    <p className="!text-[#666666] text-base font-normal">
                      {value[0] && value[0]?.format("D MMM YYYY")} -{" "}
                      {value[1] && value[1]?.format("D MMM YYYY")}
                    </p>
                  </div>
                  <div className="flex flex-col justify-between text-left hidden">
                    <div className="text-base">
                      Totale:{" "}
                      <span className="font-bold text-xl">
                        {price.totalPriceDiscounted} €
                      </span>
                    </div>
                    <div className="text-sm">
                      {value[0] && value[0].format("D MMM YYYY")} -{" "}
                      {value[1] && value[1].format("D MMM YYYY")}
                    </div>
                  </div>
                </>
              ) : null}
              <div>
                <button
                  className="text-white text-lg bg-blue rounded-lg py-3 px-10"
                  onClick={handleSave}
                >
                  Salva
                </button>
              </div>
            </div>
              <span
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  backgroundColor: "white",
                  border: "1px solid #ccc",
                  boxShadow: "0 0 5px #ccc",
                  borderRadius: "5px",
                  padding: "3px 5px",
                  fontSize: "14px",
                  transform: `translate(${data.left}px, ${data.top + 30}px)`,
                  visibility: data.visibleCheckOutOnly ? "visible" : "hidden",
                }}
              >
                Solo check Out
              </span>
              <span
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  backgroundColor: "white",
                  border: "1px solid #ccc",
                  boxShadow: "0 0 5px #ccc",
                  borderRadius: "5px",
                  padding: "3px 5px",
                  fontSize: "14px",
                  transform: `translate(${data.left}px, ${data.top + 30}px)`,
                  visibility: data.visibleMinTwoNights ? "visible" : "hidden",
                }}
              >
                Minimo due notti
              </span>
              <span
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  backgroundColor: "white",
                  border: "1px solid #ccc",
                  boxShadow: "0 0 5px #ccc",
                  borderRadius: "5px",
                  padding: "3px 5px",
                  fontSize: "14px",
                  transform: `translate(${data.left}px, ${data.top + 30}px)`,
                  visibility: data.visibleCheckInOnly ? "visible" : "hidden",
                }}
              >
                Solo check In
              </span>
          </DatePicker>
        ) : (
          <p>Caricamento...</p>
        )}
        <button
          onClick={handleBooking}
          /* disabled={!savedValue} */ className="w-full text-white bg-blue mt-4 rounded-lg py-3 disabled:cursor-not-allowed disabled:bg-slate-400 disabled:text-gray-600 hover:bg-transparent hover:text-blue transition-colors hover:border-blue border-blue border-solid border-2"
        >
          Prenota
        </button>
        {price ? (
          <div className="pt-2 text-md">
            <p className="flex justify-between">
              <div
                className="flex items-center cursor-pointer"
                onClick={() => setShowPriceDetails(!showPriceDetails)}
              >
                Totale x {price.nights} notte/i
                <span className="ml-2 text-blue">
                  <FaInfoCircle />
                </span>
              </div>
              <span>{price.totalPrice} €</span>
            </p>

            <div
              className={`flex flex-col  duration-500 transition-[max-height] overflow-hidden h-auto  ${
                showPriceDetails ? "max-h-[1000px]" : "max-h-[0]"
              }`}
            >
              {price &&
                price.daysDetails.map((day) => (
                  <div className="flex w-full justify-between mt-2">
                    <span>{day.dayDate}</span> <span>{day.priceEur} €</span>
                  </div>
                ))}{" "}
            </div>
            {price.discount > 0 && (
              <p className="flex justify-between pb-2 border-b border-gray-200">
                <span>Sconto</span> <span>-{price.discount}€</span>
              </p>
            )}

            <p className="flex justify-between pt-2 font-bold">
              <span>Totale</span> <span>{price.totalPriceDiscounted}€</span>
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
}
