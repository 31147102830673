import Calendar from "./Calendar";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import useFormState from "./hooks/useForm";
import BookingForm from "./BookingForm";
import { useEffect } from "react";
import GenericCalendar from "./components/GenericCalendar";

function App() {
  const queryClient = new QueryClient();
  const { formStep, houseId, setHouseId } = useFormState();

  useEffect(() => {
    // Retrieve house ID from URL param. Ex: fri.land/1
    const houseId = window.location.pathname.split("/");
    if (houseId[1]) {
      setHouseId(houseId[1]);
    }
  }, []);
  // If house ID, we show regular calendar ( booking calendar inside House Page )
  // If house ID is not present, we show generic calendar retrieving all houses available for selected dates
  return (
    <QueryClientProvider client={queryClient}>
      {formStep === 0 && houseId === "0" && <GenericCalendar />}
      {formStep === 0 && houseId > 0 && <Calendar />}
      {(formStep === 1 || formStep === 2) && <BookingForm />}
    </QueryClientProvider>
  );
}

export default App;
